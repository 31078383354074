body {
  margin: 0;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
	height: 100vh;
	position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
main,
footer {
	margin: 0 auto;
  max-width: 600px;
  padding: 0 1rem;
}

footer {
	margin: 1rem auto 0;
}

h1 {
  font-size: 1.75rem;
  margin: 1rem;
	position: absolute;
	z-index: 1;
	color: #fff;
	text-shadow: #000 0.0625rem 0 0.625rem;
}
