.analog-pad {
  overflow: visible;
  touch-action: none;
  max-width: 160px;

  &__recess {
    touch-action: none;
  }
  
  &__control {
    touch-action: none;
  }
}